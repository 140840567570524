import React from 'react';
import { Button, Col, Container, Form, Row, Stack, Form as BSForm } from 'react-bootstrap';
import * as Yup from 'yup';
import { API_CONFIG, DEFAULT_NEWSLETTER_NAME } from '../../../resources/constants';
import { ErrorMessage, Field, Formik, FormikHelpers } from 'formik';
import { NewsletterControllerApi } from '../../../generated';
import { TbBell } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';

const newsletterClient = new NewsletterControllerApi(API_CONFIG);

export default function NewsletterShelf() {
    const { t } = useTranslation();

    // Define initial values
    const initialValues = { email: '' };

    // Update validation schema to use translations
    const validationSchema = Yup.object({
        email: Yup.string().email(t('FORM_VALIDATION.InvalidEmailAddress')).required(t('FORM_VALIDATION.Required')),
    });

    const subscribeToNewsletter = async (values: any, formikHelpers: FormikHelpers<any>) => {
        await newsletterClient
            .subscribeToNewsletter({
                newsletterName: DEFAULT_NEWSLETTER_NAME,
                subscribeToNewsletterRequest: { email: values.email },
            })
            .catch((error) => {
                console.error(error, error.message);
                formikHelpers.setFieldError('email', error.message);
            });
    };
    return (
        <div
            style={{
                height: '600px',
                background:
                    "url('https://s3.menoita.net/static/menoita_a_woman_is_hanging_artworks_with_the_same_aspect_ration_cb8cf2bb-abb4-4789-b305-0b77f3b5505e.png') center / cover",
            }}
        >
            <Container className="h-100">
                <Row className="h-100">
                    <Col
                        md={6}
                        className="text-center text-md-start d-flex d-sm-flex d-md-flex justify-content-center align-items-center justify-content-md-start align-items-md-center justify-content-xl-center"
                    >
                        <div style={{ maxWidth: '400px' }}>
                            <h1 className="text-uppercase fw-bold" style={{ color: 'var(--bs-body-bg)' }}>
                                {t('NEWSLETTER.TITLE')}
                            </h1>
                            <p className="my-3" style={{ color: 'var(--bs-body-bg)' }}>
                                {t('NEWSLETTER.SUBTITLE')}
                            </p>
                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={subscribeToNewsletter}>
                                {({ isSubmitting, errors, touched }) => (
                                    <Form>
                                        <Stack direction="horizontal" gap={3}>
                                            <div className="w-100">
                                                <Field
                                                    type="email"
                                                    name="email"
                                                    placeholder={t('NEWSLETTER.PLACEHOLDER')}
                                                    as={BSForm.Control}
                                                    className={`form-control mb-2 ${errors.email && touched.email ? 'is-invalid' : ''}`}
                                                />
                                                <ErrorMessage name="email" component="div" className="invalid-feedback fs-7" />
                                            </div>
                                            <div>
                                                <Button
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                    className={`no-break ps-2 ${errors.email && touched.email ? 'mb-4' : 'mb-2'}`}
                                                >
                                                    <TbBell size={22} className="pb-1" /> {t('NEWSLETTER.SUBSCRIBE_BUTTON')}
                                                </Button>
                                            </div>
                                        </Stack>
                                    </Form>
                                )}
                            </Formik>
                            <p className="fs-7 mt-2" style={{ color: 'var(--bs-body-bg)' }}>
                                {t('NEWSLETTER.PRIVACY_NOTE')}
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
