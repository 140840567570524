/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchOrderFilter
 */
export interface SearchOrderFilter {
    /**
     * 
     * @type {string}
     * @memberof SearchOrderFilter
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchOrderFilter
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchOrderFilter
     */
    shippingMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchOrderFilter
     */
    trackingNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchOrderFilter
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchOrderFilter
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchOrderFilter
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchOrderFilter
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchOrderFilter
     */
    country?: string;
    /**
     * 
     * @type {Date}
     * @memberof SearchOrderFilter
     */
    estimatedDeliveryDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SearchOrderFilter
     */
    shippedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SearchOrderFilter
     */
    deliveredDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SearchOrderFilter
     */
    paymentStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchOrderFilter
     */
    paymentIntentId?: string;
    /**
     * 
     * @type {Date}
     * @memberof SearchOrderFilter
     */
    paymentDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SearchOrderFilter
     */
    checkoutId?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchOrderFilter
     */
    paymentMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchOrderFilter
     */
    orderState?: SearchOrderFilterOrderStateEnum;
    /**
     * 
     * @type {Date}
     * @memberof SearchOrderFilter
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SearchOrderFilter
     */
    lastUpdatedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SearchOrderFilter
     */
    discountId?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchOrderFilter
     */
    couponCode?: string;
}


/**
 * @export
 */
export const SearchOrderFilterOrderStateEnum = {
    PaymentPending: 'PAYMENT_PENDING',
    PaymentProcessed: 'PAYMENT_PROCESSED',
    PaymentFailed: 'PAYMENT_FAILED',
    ProviderNotified: 'PROVIDER_NOTIFIED',
    OrderInProgress: 'ORDER_IN_PROGRESS',
    OrderReadyForPickup: 'ORDER_READY_FOR_PICKUP',
    PackageInTransit: 'PACKAGE_IN_TRANSIT',
    DeliveryConfirmed: 'DELIVERY_CONFIRMED',
    OrderCanceledSystem: 'ORDER_CANCELED_SYSTEM',
    OrderCanceledClient: 'ORDER_CANCELED_CLIENT',
    RefundInitiated: 'REFUND_INITIATED',
    RefundCompleted: 'REFUND_COMPLETED'
} as const;
export type SearchOrderFilterOrderStateEnum = typeof SearchOrderFilterOrderStateEnum[keyof typeof SearchOrderFilterOrderStateEnum];


/**
 * Check if a given object implements the SearchOrderFilter interface.
 */
export function instanceOfSearchOrderFilter(value: object): value is SearchOrderFilter {
    return true;
}

export function SearchOrderFilterFromJSON(json: any): SearchOrderFilter {
    return SearchOrderFilterFromJSONTyped(json, false);
}

export function SearchOrderFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchOrderFilter {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'] == null ? undefined : json['email'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'shippingMethod': json['shippingMethod'] == null ? undefined : json['shippingMethod'],
        'trackingNumber': json['trackingNumber'] == null ? undefined : json['trackingNumber'],
        'address': json['address'] == null ? undefined : json['address'],
        'city': json['city'] == null ? undefined : json['city'],
        'state': json['state'] == null ? undefined : json['state'],
        'postalCode': json['postalCode'] == null ? undefined : json['postalCode'],
        'country': json['country'] == null ? undefined : json['country'],
        'estimatedDeliveryDate': json['estimatedDeliveryDate'] == null ? undefined : (new Date(json['estimatedDeliveryDate'])),
        'shippedDate': json['shippedDate'] == null ? undefined : (new Date(json['shippedDate'])),
        'deliveredDate': json['deliveredDate'] == null ? undefined : (new Date(json['deliveredDate'])),
        'paymentStatus': json['paymentStatus'] == null ? undefined : json['paymentStatus'],
        'paymentIntentId': json['paymentIntentId'] == null ? undefined : json['paymentIntentId'],
        'paymentDate': json['paymentDate'] == null ? undefined : (new Date(json['paymentDate'])),
        'checkoutId': json['checkoutId'] == null ? undefined : json['checkoutId'],
        'paymentMethod': json['paymentMethod'] == null ? undefined : json['paymentMethod'],
        'orderState': json['orderState'] == null ? undefined : json['orderState'],
        'createdDate': json['createdDate'] == null ? undefined : (new Date(json['createdDate'])),
        'lastUpdatedDate': json['lastUpdatedDate'] == null ? undefined : (new Date(json['lastUpdatedDate'])),
        'discountId': json['discountId'] == null ? undefined : json['discountId'],
        'couponCode': json['couponCode'] == null ? undefined : json['couponCode'],
    };
}

export function SearchOrderFilterToJSON(value?: SearchOrderFilter | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'phoneNumber': value['phoneNumber'],
        'shippingMethod': value['shippingMethod'],
        'trackingNumber': value['trackingNumber'],
        'address': value['address'],
        'city': value['city'],
        'state': value['state'],
        'postalCode': value['postalCode'],
        'country': value['country'],
        'estimatedDeliveryDate': value['estimatedDeliveryDate'] == null ? undefined : ((value['estimatedDeliveryDate']).toISOString()),
        'shippedDate': value['shippedDate'] == null ? undefined : ((value['shippedDate']).toISOString()),
        'deliveredDate': value['deliveredDate'] == null ? undefined : ((value['deliveredDate']).toISOString()),
        'paymentStatus': value['paymentStatus'],
        'paymentIntentId': value['paymentIntentId'],
        'paymentDate': value['paymentDate'] == null ? undefined : ((value['paymentDate']).toISOString()),
        'checkoutId': value['checkoutId'],
        'paymentMethod': value['paymentMethod'],
        'orderState': value['orderState'],
        'createdDate': value['createdDate'] == null ? undefined : ((value['createdDate']).toISOString()),
        'lastUpdatedDate': value['lastUpdatedDate'] == null ? undefined : ((value['lastUpdatedDate']).toISOString()),
        'discountId': value['discountId'],
        'couponCode': value['couponCode'],
    };
}

