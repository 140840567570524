import React, { useCallback, useEffect, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import ProductDetails from '../components/Product/ProductDetails';
import ProductPresentation from '../components/Product/ProductPresentation';
import ProductShelf from '../components/Shelf/product/ProductShelf';
import { useParams } from 'react-router-dom';
import { GetCollectionShelfShelfEnum, GetShelfShelfEnum, PosterControllerApi, PosterResponse } from '../generated';
import { API_CONFIG, PRODUCT_POSTER } from '../resources/constants';
import { useStateSSRInit } from '../utils/DataWrapper';
import CollectionShelf from '../components/Shelf/collections/CollectionsShelf';
import NotFound from '../components/Product/NotFound';
import SSRContext from '../service/SSRContext';
import ListingShelf from '../components/Shelf/product/ListingShelf';

const posterClient = new PosterControllerApi(API_CONFIG);

export function ProductPage() {
    const { posterName } = useParams();
    const { t } = useTranslation();

    const descriptions = useMemo(() => t('NOT_FOUND.PRODUCT_description', { returnObjects: true }) as string[], [t]);
    const placeholders = useMemo(() => t('NOT_FOUND.PRODUCT_placeholder', { returnObjects: true }) as string[], [t]);

    const fetchPoster = useCallback(async () => {
        if (!posterName) return undefined;
        try {
            return await posterClient.getByName({ name: posterName });
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }, [posterName]);

    const [poster, setPoster] = useStateSSRInit<PosterResponse | undefined>(fetchPoster, PRODUCT_POSTER);

    if (SSRContext.isInServer() && !poster) SSRContext.setStatusCode(404);

    useEffect(() => {
        if (poster && poster.defaultName !== posterName) fetchPoster().then((poster) => setPoster(poster));
    }, [poster, posterName, fetchPoster, setPoster]);

    return (
        <>
            {poster ? (
                <>
                    <Container className="mt-5 custom-container">
                        <ProductPresentation product={poster} />
                        <ProductShelf shelfEnum={GetShelfShelfEnum.FrequentlyBoughtTogether} addCart="bottom" />
                    </Container>
                    <ProductDetails product={poster} />
                    <Container className="mt-5 custom-container">
                        <ListingShelf title={t('PRODUCT_PAGE.youMightAlsoLike')} />
                        <CollectionShelf title={t('PRODUCT_PAGE.hotCollections')} shelfEnum={GetCollectionShelfShelfEnum.FrequentlyBoughtTogether} />
                    </Container>
                </>
            ) : (
                <NotFound
                    shelfEnum={GetShelfShelfEnum.YouAlsoMightLike}
                    title={t('NOT_FOUND.PRODUCT_title')}
                    description={descriptions}
                    placeholder={placeholders}
                />
            )}
        </>
    );
}
