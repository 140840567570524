import React, { useContext, useState } from 'react';
import { Row, Col, Button, Form, Container } from 'react-bootstrap';
import { TbMoodSadSquint, TbSearch } from 'react-icons/tb';
import RandomGenerator from '../../utils/Random';
import ProductShelf from '../Shelf/product/ProductShelf';
import { GetShelfShelfEnum } from '../../generated';
import { useNavigate } from 'react-router-dom';
import { SearchContext } from '../../provider/SearchProvider';
import { useTranslation } from 'react-i18next';

type Props = {
    readonly shelfEnum?: GetShelfShelfEnum;
    readonly title: string; // Poster not found
    readonly description: string[];
    readonly placeholder: string[];
    readonly onClickCallback?: () => void;
};

export default function NotFound({ shelfEnum, title, description, placeholder, onClickCallback }: Props) {
    const R = new RandomGenerator();
    const navigate = useNavigate();
    const [searchTermOnSearchBar, setSearchTermOnSearchBar] = useState<string>('');

    const { t } = useTranslation();
    const { setSearchTerm } = useContext(SearchContext);

    const descriptionIndex = Math.floor(R.random() * description.length);
    const placeholderIndex = description.length != placeholder.length ? Math.floor(R.random() * placeholder.length) : descriptionIndex;

    const handleSearch = () => {
        if (onClickCallback) onClickCallback();
        setSearchTerm(searchTermOnSearchBar);
        navigate(searchTermOnSearchBar ? `/search?q=${searchTermOnSearchBar}` : '/listing');
    };

    return (
        <Container>
            <h1 className="display-3 text-center mt-5">
                {title}
                <TbMoodSadSquint />
            </h1>
            <p className="text-center w-75 mx-auto mt-4 lead">{description[descriptionIndex]}</p>
            <Form>
                <Form.Group className="mt-4 w-75 mx-auto">
                    <Row>
                        <Col>
                            <Form.Control
                                className="mb-3"
                                placeholder={placeholder[placeholderIndex]}
                                style={{
                                    minWidth: '170px',
                                }}
                                value={searchTermOnSearchBar}
                                onChange={(e) => setSearchTermOnSearchBar(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                            />
                        </Col>
                        <Col xs="auto">
                            <Button onClick={handleSearch}>
                                <TbSearch /> {t('BUTTON.search_now')}
                            </Button>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
            {shelfEnum && <ProductShelf shelfEnum={shelfEnum} />}
        </Container>
    );
}
