import React, { useContext, useEffect, useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import CollectionsPreview from './CollectionsPreview';
import { GetShelfShelfEnum } from '../../generated';
import NotFound from '../Product/NotFound';
import { CollectionContext } from '../../provider/CollectionProvider';
import { useTranslation } from 'react-i18next';

export default function CollectionsListing() {
    const { collections, firstIsLoading, isLoading, loadMoreCollections } = useContext(CollectionContext);

    const { t } = useTranslation();

    const descriptions = useMemo(() => t('NOT_FOUND.LISTING_COLLECTION_paragraph', { returnObjects: true }) as string[], [t]);
    const placeholders = useMemo(() => t('NOT_FOUND.LISTING_COLLECTION_placeholders', { returnObjects: true }) as string[], [t]);

    const handleScroll = () => {
        const scrollTop = window.innerHeight + document.documentElement.scrollTop;
        const offsetHeight = document.documentElement.offsetHeight;

        if (scrollTop >= offsetHeight - 200 && !isLoading && !firstIsLoading) {
            loadMoreCollections();
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isLoading, firstIsLoading]);

    return (
        <>
            {(collections?.length ?? 0) > 0 || firstIsLoading ? (
                <Row className="mb-5 mt-2">
                    {/* Display either actual collections or placeholders based on firstIsLoading */}
                    {firstIsLoading
                        ? Array.from(Array(9).keys()).map((key) => (
                              <Col md={12} lg={6} xl={4} key={`placeholder-${key}`}>
                                  <CollectionsPreview firstIsLoading={firstIsLoading} />
                              </Col>
                          ))
                        : collections?.map((collection) => (
                              <Col md={12} lg={6} xl={4} key={collection.collectionId}>
                                  <CollectionsPreview collection={collection} />
                              </Col>
                          ))}
                </Row>
            ) : (
                <NotFound
                    shelfEnum={GetShelfShelfEnum.YouAlsoMightLike}
                    title={t('NOT_FOUND.LISTING_COLLECTION_title')}
                    description={descriptions}
                    placeholder={placeholders}
                />
            )}
        </>
    );
}
