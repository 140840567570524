/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateTagRequest
 */
export interface CreateTagRequest {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CreateTagRequest
     */
    newTagNameLanguages?: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateTagRequest
     */
    parents?: Array<string>;
}

/**
 * Check if a given object implements the CreateTagRequest interface.
 */
export function instanceOfCreateTagRequest(value: object): value is CreateTagRequest {
    return true;
}

export function CreateTagRequestFromJSON(json: any): CreateTagRequest {
    return CreateTagRequestFromJSONTyped(json, false);
}

export function CreateTagRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTagRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'newTagNameLanguages': json['newTagNameLanguages'] == null ? undefined : json['newTagNameLanguages'],
        'parents': json['parents'] == null ? undefined : json['parents'],
    };
}

export function CreateTagRequestToJSON(value?: CreateTagRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'newTagNameLanguages': value['newTagNameLanguages'],
        'parents': value['parents'],
    };
}

