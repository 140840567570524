import React from 'react';
import './HorizontalLeque.scss';

type PosterShelfProps = {
    posters: {
        src: string;
        alt?: string;
    }[];
    direction?: 'first' | 'last'; // Determines stacking direction
};

export default function HorizontalLeque({ posters, direction = 'first' }: PosterShelfProps) {
    return (
        <div className={`poster-rack ${direction}`} style={{ '--poster-total': posters.length } as React.CSSProperties}>
            {posters.map((poster, index) => (
                <div key={index} className="poster" style={{ '--poster-index': index } as React.CSSProperties}>
                    <img src={poster.src} alt={poster.alt || `Poster ${index + 1}`} />
                </div>
            ))}
        </div>
    );
}
