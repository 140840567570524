import React from 'react';
import './LequeMenu.scss';

type Props = {
    images: { src: string; alt?: string }[];
};

const LequeMenu = ({ images }: Props) => {
    if (!images || images.length < 3) {
        return <></>;
    }

    const has3Images = images.length == 3;

    return (
        <div className="leque-menu d-flex justify-content-center align-items-center">
            <div className="image-container">
                {images.slice(0, 5).map((image, index) => (
                    <img
                        key={index}
                        src={image.src}
                        alt={image.alt || `Image ${index + 1 + (has3Images ? 1 : 0)}`}
                        className={`leque-image position-absolute image-${index + 1 + (has3Images ? 1 : 0)}`}
                    />
                ))}
            </div>
        </div>
    );
};

export default LequeMenu;
