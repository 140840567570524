import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { LuGuitar } from 'react-icons/lu';
import { TbPlane, TbBrush, TbVinyl, TbLeaf } from 'react-icons/tb';
import { TiFlashOutline } from 'react-icons/ti';
import LequeMenu from '../Shelf/leque/LequeShelf';
import MenuElement from './MenuElement';
import { useTranslation } from 'react-i18next';

export default function CollectionsMenuContent(htmlProps?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    const { t } = useTranslation();
    const size = 20;

    return (
        <div {...htmlProps}>
            <Container>
                <Row>
                    <Col
                        md={{
                            offset: 1,
                        }}
                    >
                        <h3 className="mt-1">{t('MENU.COLLECTIONS.CONTENT_TITLE')}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col
                        md={{
                            offset: 1,
                            span: 3,
                        }}
                        className="pt-3"
                    >
                        <MenuElement
                            link="/collections"
                            text={t('MENU.COLLECTIONS.WANDERLUST_WONDERS.TITLE')}
                            description={t('MENU.COLLECTIONS.WANDERLUST_WONDERS.DESCRIPTION')}
                            icon={<TbPlane size={size} />}
                        />
                        <MenuElement
                            link="/collections"
                            text={t('MENU.COLLECTIONS.ARTISTIC_EXPRESSIONS.TITLE')}
                            description={t('MENU.COLLECTIONS.ARTISTIC_EXPRESSIONS.DESCRIPTION')}
                            icon={<TbBrush size={size} />}
                        />
                        <MenuElement
                            link="/collections"
                            text={t('MENU.COLLECTIONS.RETRO_REVIVAL.TITLE')}
                            description={t('MENU.COLLECTIONS.RETRO_REVIVAL.DESCRIPTION')}
                            icon={<TbVinyl size={size} />}
                        />
                    </Col>
                    <Col md={3} className="pt-3">
                        <MenuElement
                            link="/listing"
                            text={t('MENU.COLLECTIONS.BEST_SELLERS.TITLE')}
                            description={t('MENU.COLLECTIONS.BEST_SELLERS.DESCRIPTION')}
                            icon={<TiFlashOutline size={size} />}
                        />
                        <MenuElement
                            link="/collections"
                            text={t('MENU.COLLECTIONS.PERSONAL_PASSIONS.TITLE')}
                            description={t('MENU.COLLECTIONS.PERSONAL_PASSIONS.DESCRIPTION')}
                            icon={<LuGuitar size={size} />}
                        />
                        <MenuElement
                            link="/collections"
                            text={t('MENU.COLLECTIONS.MINDFUL_LIVING.TITLE')}
                            description={t('MENU.COLLECTIONS.MINDFUL_LIVING.DESCRIPTION')}
                            icon={<TbLeaf size={size} />}
                        />
                    </Col>
                    <Col>
                        <LequeMenu
                            images={[
                                {
                                    src: 'https://s3.menoita.net/lumiglyph/small/menoita_abstract_girl_in_space_in_the_style_of_cyril_rolando_in_3cddea5f-7fa3-4435-a5e7-4b795537ea29-1731874213559.webp',
                                },
                                {
                                    src: 'https://s3.menoita.net/lumiglyph/small/menoita_abstract_girl_in_space_in_the_style_of_cyril_rolando_in_3cddea5f-7fa3-4435-a5e7-4b795537ea29-1731874213559.webp',
                                },
                                {
                                    src: 'https://s3.menoita.net/lumiglyph/small/menoita_abstract_girl_in_space_in_the_style_of_cyril_rolando_in_3cddea5f-7fa3-4435-a5e7-4b795537ea29-1731874213559.webp',
                                },
                                {
                                    src: 'https://s3.menoita.net/lumiglyph/small/menoita_abstract_girl_in_space_in_the_style_of_cyril_rolando_in_3cddea5f-7fa3-4435-a5e7-4b795537ea29-1731874213559.webp',
                                },
                                {
                                    src: 'https://s3.menoita.net/lumiglyph/small/menoita_abstract_girl_in_space_in_the_style_of_cyril_rolando_in_3cddea5f-7fa3-4435-a5e7-4b795537ea29-1731874213559.webp',
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
