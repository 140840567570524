import React from 'react';
import Shelf from '../Shelf';
import { Image, Stack } from 'react-bootstrap';
import './CollectionShelf.scss';
import { CollectionResponse, CollectionShelfDetails, GetCollectionShelfShelfEnum, ShelfControllerApi } from '../../../generated';
import { API_CONFIG } from '../../../resources/constants';
import { useStateSSRInit } from '../../../utils/DataWrapper';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createPosterImageUrl, ImageSize } from '../../../utils/Utils';
import { ImageWithPlaceHolder } from '../../Image/ImageWithPlaceHolder';

type Props = {
    readonly title?: string;
    readonly shelfEnum: GetCollectionShelfShelfEnum;
    readonly hr?: boolean;
};

const shelfClient = new ShelfControllerApi(API_CONFIG);

function CollectionPlace({ collection }: { collection: CollectionResponse }) {
    return (
        <div className="collection-place mx-2 mt-2">
            <Link to={`/collection/${collection.defaultName}`} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                <h1 className="collection-place-title display-4 fs-5 p-0 ms-2 text-start text-decoration-none">
                    {collection.translatedName ?? collection.defaultName}
                </h1>
                <Stack direction="horizontal" gap={1} className="w-100 overflow-hidden collection-shelf-row">
                    {collection?.posters?.slice(0, 2).map((product) => (
                        <ImageWithPlaceHolder
                            responsive={false}
                            parentSelector=".collection-place"
                            aspectRatio={1}
                            key={product.posterId}
                            src={createPosterImageUrl(ImageSize.Small, product.image)}
                            alt={product.altText}
                            className="collection-place-image"
                        />
                    ))}
                </Stack>
                <Stack direction="horizontal" gap={1} className="w-100 overflow-hidden mt-1 collection-shelf-row">
                    {collection?.posters?.slice(2, 4).map((product) => (
                        <ImageWithPlaceHolder
                            responsive={false}
                            parentSelector=".collection-place"
                            aspectRatio={1}
                            key={product.posterId}
                            src={createPosterImageUrl(ImageSize.Small, product.image)}
                            alt={product.altText}
                            className="collection-place-image"
                        />
                    ))}
                </Stack>
            </Link>
        </div>
    );
}

export default function CollectionShelf({ hr, title, shelfEnum, ...htmlProps }: Props & React.HTMLAttributes<HTMLDivElement>) {
    const fetchCollection = async (): Promise<CollectionShelfDetails | undefined> => {
        try {
            return await shelfClient.getCollectionShelf({ shelf: shelfEnum });
        } catch (error) {
            console.error(error);
            return undefined;
        }
    };

    const { t } = useTranslation();
    const [collectionShelf] = useStateSSRInit<CollectionShelfDetails | undefined>(fetchCollection, shelfEnum);

    return (
        <div {...htmlProps}>
            {(collectionShelf?.collections?.length ?? 0) > 0 && (
                <div className="collection-shelf">
                    {/* @ts-ignore */}
                    <Shelf title={title || (shelfEnum && t('SHELF.' + shelfEnum))} className="mb-4" hr={hr} userGrid={false}>
                        {collectionShelf?.collections?.map((collection) => (
                            <CollectionPlace key={collection.defaultName} collection={collection} />
                        ))}
                    </Shelf>
                </div>
            )}
        </div>
    );
}
