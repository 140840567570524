import React from 'react';
import { CollectionResponse, CollectionShelfDetails, GetCollectionShelfShelfEnum, ShelfControllerApi } from '../../../generated';
import { useStateSSRInit } from '../../../utils/DataWrapper';
import Shelf from '../Shelf';
import { API_CONFIG } from '../../../resources/constants';
import { Stack, Image, Row, Col } from 'react-bootstrap';
import './TwoStoreCollectionShelf.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createPosterImageUrl, ImageSize } from '../../../utils/Utils';

type Props = {
    readonly title: string;
    readonly subtitle?: string;
    readonly shelf: GetCollectionShelfShelfEnum;
    readonly discoverMore?: boolean;
};

const shelfClient = new ShelfControllerApi(API_CONFIG);

type HorizontalCollectionPlaceProps = {
    readonly collection: CollectionResponse;
};

const columns = 3;

export function HorizontalCollectionPlace({ collection }: HorizontalCollectionPlaceProps) {
    return (
        <Link to={`/collection/${collection.defaultName}`} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
            <ul className="list-unstyled two-store-collection-shelf">
                <li>
                    <Row className="align-items-center two-store-collection-shelf-row ">
                        {collection?.posters?.slice(0, 4).map((product) => (
                            <Col xs={3} key={product.posterId}>
                                <Image
                                    src={createPosterImageUrl(ImageSize.Normal, product.image)}
                                    alt={product.altText}
                                    className="two-store-collection-shelf-img"
                                />
                            </Col>
                        ))}
                    </Row>
                </li>
                <li>
                    <h3 className="display-4 fs-4 mt-3 ms-0 ps-0">{collection.translatedName}</h3>
                </li>
            </ul>
        </Link>
    );
}

export default function TwoStoreCollectionShelf({ title, subtitle, shelf, discoverMore, ...htmlProps }: Props & React.HTMLAttributes<HTMLDivElement>) {
    const { t } = useTranslation();

    const fetchCollection = async (): Promise<CollectionShelfDetails | undefined> => {
        try {
            return await shelfClient.getCollectionShelf({ shelf: shelf });
        } catch (error) {
            console.error(error);
            return undefined;
        }
    };

    const [collectionShelf] = useStateSSRInit<CollectionShelfDetails | undefined>(fetchCollection, shelf);

    // Function to create a map of collections grouped by (index % 3) with TypeScript generic types
    const groupCollectionsByIndex = <T,>(collections: T[]): Record<number, T[]> => {
        return collections.slice(0, columns * 2).reduce((acc: Record<number, T[]>, collection, index) => {
            const key = index % columns;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(collection);
            return acc;
        }, {} as Record<number, T[]>);
    };

    const groupedCollections = groupCollectionsByIndex(collectionShelf?.collections ?? []);

    return (
        <div {...htmlProps}>
            {(collectionShelf?.collections?.length ?? 0) > 0 && (
                <>
                    <div className="two-stores-shelf">
                        <div className="text-center">
                            <h1 className="display-4">{title}</h1>
                            {subtitle && (
                                <p key={subtitle} className="fs-4">
                                    {subtitle}
                                </p>
                            )}
                        </div>
                        <Shelf className="mb-4" gridColumns={columns} center={false}>
                            {Object.entries(groupedCollections).flatMap(([groupKey, collections]) => (
                                <Stack gap={1} className="overflow-hidden" key={groupKey}>
                                    {collections.map((collection) => (
                                        <HorizontalCollectionPlace key={collection.collectionId} collection={collection} />
                                    ))}
                                </Stack>
                            ))}
                        </Shelf>
                    </div>
                    <div className="text-center h-100 mt-3 pt-3">
                        {discoverMore && (
                            <Link
                                to="/collections"
                                className="primary-button text-decoration-none shadow-sm"
                                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                            >
                                {t('BUTTON.discoverMore')}
                            </Link>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
