/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NamedUserResponse
 */
export interface NamedUserResponse {
    /**
     * 
     * @type {string}
     * @memberof NamedUserResponse
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof NamedUserResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof NamedUserResponse
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof NamedUserResponse
     */
    email?: string;
}

/**
 * Check if a given object implements the NamedUserResponse interface.
 */
export function instanceOfNamedUserResponse(value: object): value is NamedUserResponse {
    return true;
}

export function NamedUserResponseFromJSON(json: any): NamedUserResponse {
    return NamedUserResponseFromJSONTyped(json, false);
}

export function NamedUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NamedUserResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'] == null ? undefined : json['userId'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'email': json['email'] == null ? undefined : json['email'],
    };
}

export function NamedUserResponseToJSON(value?: NamedUserResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
    };
}

