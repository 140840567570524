/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { SimpleProviderResponse } from './SimpleProviderResponse';
import {
    SimpleProviderResponseFromJSON,
    SimpleProviderResponseFromJSONTyped,
    SimpleProviderResponseToJSON,
} from './SimpleProviderResponse';

/**
 * 
 * @export
 * @interface UserProviderResponse
 */
export interface UserProviderResponse {
    /**
     * 
     * @type {string}
     * @memberof UserProviderResponse
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProviderResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProviderResponse
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProviderResponse
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProviderResponse
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProviderResponse
     */
    preferredLanguage?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProviderResponse
     */
    preferredCurrency?: string;
    /**
     * 
     * @type {Address}
     * @memberof UserProviderResponse
     */
    address?: Address;
    /**
     * 
     * @type {Date}
     * @memberof UserProviderResponse
     */
    birthdate?: Date;
    /**
     * 
     * @type {Set<string>}
     * @memberof UserProviderResponse
     */
    roles?: Set<UserProviderResponseRolesEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof UserProviderResponse
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProviderResponse
     */
    image?: string;
    /**
     * 
     * @type {Set<SimpleProviderResponse>}
     * @memberof UserProviderResponse
     */
    providers?: Set<SimpleProviderResponse>;
}


/**
 * @export
 */
export const UserProviderResponseRolesEnum = {
    User: 'ROLE_USER',
    Provider: 'ROLE_PROVIDER',
    Admin: 'ROLE_ADMIN'
} as const;
export type UserProviderResponseRolesEnum = typeof UserProviderResponseRolesEnum[keyof typeof UserProviderResponseRolesEnum];


/**
 * Check if a given object implements the UserProviderResponse interface.
 */
export function instanceOfUserProviderResponse(value: object): value is UserProviderResponse {
    return true;
}

export function UserProviderResponseFromJSON(json: any): UserProviderResponse {
    return UserProviderResponseFromJSONTyped(json, false);
}

export function UserProviderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProviderResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'] == null ? undefined : json['userId'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'email': json['email'] == null ? undefined : json['email'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'preferredLanguage': json['preferredLanguage'] == null ? undefined : json['preferredLanguage'],
        'preferredCurrency': json['preferredCurrency'] == null ? undefined : json['preferredCurrency'],
        'address': json['address'] == null ? undefined : AddressFromJSON(json['address']),
        'birthdate': json['birthdate'] == null ? undefined : (new Date(json['birthdate'])),
        'roles': json['roles'] == null ? undefined : json['roles'],
        'enabled': json['enabled'] == null ? undefined : json['enabled'],
        'image': json['image'] == null ? undefined : json['image'],
        'providers': json['providers'] == null ? undefined : (new Set((json['providers'] as Array<any>).map(SimpleProviderResponseFromJSON))),
    };
}

export function UserProviderResponseToJSON(value?: UserProviderResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'phoneNumber': value['phoneNumber'],
        'preferredLanguage': value['preferredLanguage'],
        'preferredCurrency': value['preferredCurrency'],
        'address': AddressToJSON(value['address']),
        'birthdate': value['birthdate'] == null ? undefined : ((value['birthdate']).toISOString()),
        'roles': value['roles'] == null ? undefined : Array.from(value['roles'] as Set<any>),
        'enabled': value['enabled'],
        'image': value['image'],
        'providers': value['providers'] == null ? undefined : (Array.from(value['providers'] as Set<any>).map(SimpleProviderResponseToJSON)),
    };
}

