import React, { useState, useRef, useEffect, ReactNode } from 'react';
import { Container, Nav } from 'react-bootstrap';
import './TopMenu.scss';
import { Link } from 'react-router-dom';

type Props = {
    readonly content: {
        label: string;
        link: string;
        content: ReactNode;
        width: number;
    }[];
};

export default function HoverDropdown({ content }: Props) {
    const [popupVisibleNumber, setPopupVisibleNumber] = useState<number | null>(null);
    const [prevPopupVisible, setPrevPopupVisible] = useState<number | null>(null);

    const hoverRefs = useRef<(HTMLDivElement | null)[]>([]);
    const hidePopupTimeout = useRef<NodeJS.Timeout | null>(null);

    const setPopupVisible = (index: number | null) => {
        setPrevPopupVisible(popupVisibleNumber);
        setPopupVisibleNumber(index);
    };

    const handleMouseEnter = (index: number) => {
        if (hidePopupTimeout.current) {
            clearTimeout(hidePopupTimeout.current);
            hidePopupTimeout.current = null;
        }
        if (popupVisibleNumber != index) {
            setPopupVisible(index);
        }
    };

    const handleMouseLeave = () => {
        hidePopupTimeout.current = setTimeout(() => {
            setPopupVisible(null);
        }, 150);
    };

    const handlePopupMouseEnter = () => {
        if (hidePopupTimeout.current) {
            clearTimeout(hidePopupTimeout.current);
            hidePopupTimeout.current = null;
        }
    };

    const handlePopupMouseLeave = () => {
        hidePopupTimeout.current = setTimeout(() => {
            setPopupVisible(null);
        }, 200);
    };

    useEffect(() => {
        return () => {
            if (hidePopupTimeout.current) {
                clearTimeout(hidePopupTimeout.current);
            }
        };
    }, []);

    return (
        <>
            <Container className="text-center w-100 mx-auto">
                {content.map((item, index) => (
                    <div
                        key={item.label}
                        className="hover-dropdown py-1"
                        ref={(el) => (hoverRefs.current[index] = el)}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Nav.Link
                            as={Link}
                            to={item.link}
                            className={`${popupVisibleNumber !== null && popupVisibleNumber == index ? 'top-menu-active' : ''} top-menu-element`}
                        >
                            {item.label}
                        </Nav.Link>
                    </div>
                ))}
            </Container>
            {popupVisibleNumber !== null && content[popupVisibleNumber]?.content && (
                <div
                    className={`popup p-3 ${popupVisibleNumber !== null ? 'popup-visible animated' : ''} ${prevPopupVisible != null ? 'left-animated ' : ''} `}
                    onMouseEnter={handlePopupMouseEnter}
                    onMouseLeave={handlePopupMouseLeave}
                    style={{
                        width: '100vw',
                    }}
                >
                    <div className="popup-body m-3">{content[popupVisibleNumber].content}</div>
                </div>
            )}
        </>
    );
}
