import React, { ReactNode } from 'react';
import { Stack, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

type ElementProps = {
    readonly link: string;
    readonly text: string;
    readonly description?: string;
    readonly icon?: ReactNode;
};

export default function MenuElement({ link, text, icon, description }: ElementProps) {
    return (
        <div
            style={{
                color: 'grey',
            }}
        >
            <Stack direction="vertical" gap={0} className="mb-2 mt-1">
                <Stack direction="horizontal" gap={2}>
                    {icon}
                    <Nav.Link className="p-0 m-0" to={link} as={Link}>
                        {text}
                    </Nav.Link>
                </Stack>
                {description && <p className="fs-7 p-0 m-0">{description}</p>}
            </Stack>
        </div>
    );
}
