import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { TbHome, TbFrame, TbBrush, TbBallFootball, TbMoustache, TbSnowman, TbDeviceGamepad2, TbGift, TbDeviceTv, TbMap, TbPiano } from 'react-icons/tb';
import { TiFlashOutline } from 'react-icons/ti';
import HorizontalLeque from '../Shelf/leque/HorizontalLeque';
import MenuElement from './MenuElement';
import { useTranslation } from 'react-i18next';

export default function PostersMenuContent(htmlProps?: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
    const { t } = useTranslation();
    const size = 20;

    return (
        <div {...htmlProps}>
            <Container>
                <h3 className="mt-1">{t('MENU.POSTERS.CONTENT_TITLE')}</h3>
                <Row>
                    <Col md={8}>
                        <Row>
                            <Col>
                                <MenuElement
                                    link="/listing"
                                    text={t('MENU.POSTERS.GIFT_IDEAS.TITLE')}
                                    description={t('MENU.POSTERS.GIFT_IDEAS.DESCRIPTION')}
                                    icon={<TbGift size={size} />}
                                />
                                <MenuElement
                                    link="/listing"
                                    text={t('MENU.POSTERS.TV_TIME.TITLE')}
                                    description={t('MENU.POSTERS.TV_TIME.DESCRIPTION')}
                                    icon={<TbDeviceTv size={size} />}
                                />
                                <MenuElement
                                    link="/listing"
                                    text={t('MENU.POSTERS.TRAVEL_TALES.TITLE')}
                                    description={t('MENU.POSTERS.TRAVEL_TALES.DESCRIPTION')}
                                    icon={<TbMap size={size} />}
                                />
                                <MenuElement
                                    link="/listing"
                                    text={t('MENU.POSTERS.MUSIC_MANIA.TITLE')}
                                    description={t('MENU.POSTERS.MUSIC_MANIA.DESCRIPTION')}
                                    icon={<TbPiano size={size} />}
                                />
                            </Col>
                            <Col>
                                <MenuElement
                                    link="/listing"
                                    text={t('MENU.POSTERS.BEST_SELLERS.TITLE')}
                                    description={t('MENU.POSTERS.BEST_SELLERS.DESCRIPTION')}
                                    icon={<TiFlashOutline size={size} />}
                                />
                                <MenuElement
                                    link="/listing"
                                    text={t('MENU.POSTERS.HOME_LIVING.TITLE')}
                                    description={t('MENU.POSTERS.HOME_LIVING.DESCRIPTION')}
                                    icon={<TbHome size={size} />}
                                />
                                <MenuElement
                                    link="/listing"
                                    text={t('MENU.POSTERS.ABSTRACT_ARTISTRY.TITLE')}
                                    description={t('MENU.POSTERS.ABSTRACT_ARTISTRY.DESCRIPTION')}
                                    icon={<TbFrame size={size} />}
                                />
                                <MenuElement
                                    link="/listing"
                                    text={t('MENU.POSTERS.MINIMALIST_MOODS.TITLE')}
                                    description={t('MENU.POSTERS.MINIMALIST_MOODS.DESCRIPTION')}
                                    icon={<TbBrush size={size} />}
                                />
                            </Col>
                            <Col>
                                <MenuElement
                                    link="/listing"
                                    text={t('MENU.POSTERS.SPORTS_SPHERE.TITLE')}
                                    description={t('MENU.POSTERS.SPORTS_SPHERE.DESCRIPTION')}
                                    icon={<TbBallFootball size={size} />}
                                />
                                <MenuElement
                                    link="/listing"
                                    text={t('MENU.POSTERS.VINTAGE_VAULTS.TITLE')}
                                    description={t('MENU.POSTERS.VINTAGE_VAULTS.DESCRIPTION')}
                                    icon={<TbMoustache size={size} />}
                                />
                                <MenuElement
                                    link="/listing"
                                    text={t('MENU.POSTERS.ANIME_ARENA.TITLE')}
                                    description={t('MENU.POSTERS.ANIME_ARENA.DESCRIPTION')}
                                    icon={<TbSnowman size={size} />}
                                />
                                <MenuElement
                                    link="/listing"
                                    text={t('MENU.POSTERS.GAMING_GALLERY.TITLE')}
                                    description={t('MENU.POSTERS.GAMING_GALLERY.DESCRIPTION')}
                                    icon={<TbDeviceGamepad2 size={size} />}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4}>
                        <HorizontalLeque
                            posters={[
                                {
                                    src: 'https://s3.menoita.net/lumiglyph/small/menoita_abstract_girl_in_space_in_the_style_of_cyril_rolando_in_3cddea5f-7fa3-4435-a5e7-4b795537ea29-1731874213559.webp',
                                },
                                {
                                    src: 'https://s3.menoita.net/lumiglyph/small/menoita_abstract_girl_in_space_in_the_style_of_cyril_rolando_in_3cddea5f-7fa3-4435-a5e7-4b795537ea29-1731874213559.webp',
                                },
                                {
                                    src: 'https://s3.menoita.net/lumiglyph/small/menoita_abstract_girl_in_space_in_the_style_of_cyril_rolando_in_3cddea5f-7fa3-4435-a5e7-4b795537ea29-1731874213559.webp',
                                },
                                {
                                    src: 'https://s3.menoita.net/lumiglyph/small/menoita_abstract_girl_in_space_in_the_style_of_cyril_rolando_in_3cddea5f-7fa3-4435-a5e7-4b795537ea29-1731874213559.webp',
                                },
                                {
                                    src: 'https://s3.menoita.net/lumiglyph/small/menoita_abstract_girl_in_space_in_the_style_of_cyril_rolando_in_3cddea5f-7fa3-4435-a5e7-4b795537ea29-1731874213559.webp',
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
