/* tslint:disable */
/* eslint-disable */
/**
 * Phoenix API
 * This specification exposes endpoints for the phoenix api.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: phenix@gmail.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimpleOrderResponse
 */
export interface SimpleOrderResponse {
    /**
     * 
     * @type {number}
     * @memberof SimpleOrderResponse
     */
    orderId?: number;
    /**
     * 
     * @type {string}
     * @memberof SimpleOrderResponse
     */
    shippingMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleOrderResponse
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleOrderResponse
     */
    paymentMethod?: string;
    /**
     * 
     * @type {Date}
     * @memberof SimpleOrderResponse
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SimpleOrderResponse
     */
    deliveredDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SimpleOrderResponse
     */
    lastUpdatedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SimpleOrderResponse
     */
    paymentDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof SimpleOrderResponse
     */
    totalAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof SimpleOrderResponse
     */
    orderState?: SimpleOrderResponseOrderStateEnum;
    /**
     * 
     * @type {string}
     * @memberof SimpleOrderResponse
     */
    couponCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleOrderResponse
     */
    currency?: string;
}


/**
 * @export
 */
export const SimpleOrderResponseOrderStateEnum = {
    PaymentPending: 'PAYMENT_PENDING',
    PaymentProcessed: 'PAYMENT_PROCESSED',
    PaymentFailed: 'PAYMENT_FAILED',
    ProviderNotified: 'PROVIDER_NOTIFIED',
    OrderInProgress: 'ORDER_IN_PROGRESS',
    OrderReadyForPickup: 'ORDER_READY_FOR_PICKUP',
    PackageInTransit: 'PACKAGE_IN_TRANSIT',
    DeliveryConfirmed: 'DELIVERY_CONFIRMED',
    OrderCanceledSystem: 'ORDER_CANCELED_SYSTEM',
    OrderCanceledClient: 'ORDER_CANCELED_CLIENT',
    RefundInitiated: 'REFUND_INITIATED',
    RefundCompleted: 'REFUND_COMPLETED'
} as const;
export type SimpleOrderResponseOrderStateEnum = typeof SimpleOrderResponseOrderStateEnum[keyof typeof SimpleOrderResponseOrderStateEnum];


/**
 * Check if a given object implements the SimpleOrderResponse interface.
 */
export function instanceOfSimpleOrderResponse(value: object): value is SimpleOrderResponse {
    return true;
}

export function SimpleOrderResponseFromJSON(json: any): SimpleOrderResponse {
    return SimpleOrderResponseFromJSONTyped(json, false);
}

export function SimpleOrderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimpleOrderResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'orderId': json['orderId'] == null ? undefined : json['orderId'],
        'shippingMethod': json['shippingMethod'] == null ? undefined : json['shippingMethod'],
        'country': json['country'] == null ? undefined : json['country'],
        'paymentMethod': json['paymentMethod'] == null ? undefined : json['paymentMethod'],
        'createdDate': json['createdDate'] == null ? undefined : (new Date(json['createdDate'])),
        'deliveredDate': json['deliveredDate'] == null ? undefined : (new Date(json['deliveredDate'])),
        'lastUpdatedDate': json['lastUpdatedDate'] == null ? undefined : (new Date(json['lastUpdatedDate'])),
        'paymentDate': json['paymentDate'] == null ? undefined : (new Date(json['paymentDate'])),
        'totalAmount': json['totalAmount'] == null ? undefined : json['totalAmount'],
        'orderState': json['orderState'] == null ? undefined : json['orderState'],
        'couponCode': json['couponCode'] == null ? undefined : json['couponCode'],
        'currency': json['currency'] == null ? undefined : json['currency'],
    };
}

export function SimpleOrderResponseToJSON(value?: SimpleOrderResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'orderId': value['orderId'],
        'shippingMethod': value['shippingMethod'],
        'country': value['country'],
        'paymentMethod': value['paymentMethod'],
        'createdDate': value['createdDate'] == null ? undefined : ((value['createdDate']).toISOString()),
        'deliveredDate': value['deliveredDate'] == null ? undefined : ((value['deliveredDate']).toISOString()),
        'lastUpdatedDate': value['lastUpdatedDate'] == null ? undefined : ((value['lastUpdatedDate']).toISOString()),
        'paymentDate': value['paymentDate'] == null ? undefined : ((value['paymentDate']).toISOString()),
        'totalAmount': value['totalAmount'],
        'orderState': value['orderState'],
        'couponCode': value['couponCode'],
        'currency': value['currency'],
    };
}

